module.exports={
  "Address": "アドレス",
  "Address: %s": "アドレス: %0",
  "Block %s": "ブロック %0",
  "Block #%s: %s": "ブロック #%0: %1",
  "Coinbase": "コインベース",
  "Details": "詳細",
  "Height": "ブロック高",
  "In best chain (%s confirmations)": [
    "最長チェーン(%0 検証)",
    "最長チェーン(%0 検証)"
  ],
  "Included in Block": "ブロック内容",
  "lang_id": "jp",
  "lang_name": "日本語",
  "Loading...": "ローディング中",
  "Load more": "さらに表示",
  "Lock time": "ロックタイム",
  "Next": "次へ",
  "Nonstandard": "ノン・スタンダード",
  "No results found": "結果がありません",
  "OP_RETURN data": "OP_RETURN データ",
  "Output in parent chain": "親チェーンのアウトプット",
  "Page Not Found": "ページが見つかりません。",
  "Peg-out address": "Peg-out アドレス",
  "Peg-out to": "Peg-out先",
  "Previous": "前に",
  "Search for block height, hash, transaction, or address": "ブロック高、ハッシュ、トランザクション又はアドレスを検索する",
  "Size (KB)": "サイズ(バイト)",
  "%s of %s Transactions": "トランザクション数 %0 / %1",
  "Spent by": "使用者",
  "Status": "ステータス",
  "%s Transactions": [
    "%0 トランザクション",
    "%0 トランザクション"
  ],
  "Timestamp": "タイムスタンプ",
  "Transaction": "トランザクション",
  "Transaction fees": "トランザクション手数料",
  "Transactions": "トランザクション",
  "Transaction: %s": "トランザクション: %0",
  "Type": "タイプ",
  "Unspent": "未使用",
  "Version": "バージョン",
  "We encountered an error. Please try again later.": "エラーが発生しました。後ほどもう一度お試し下さい。",
  "Weight (KWU)": "ブロックウェイト"
}
