module.exports={
  "Address": "Адрес",
  "Address: %s": "Адрес: %0",
  "Block %s": "Блок %0",
  "Block #%s: %s": "Блок #%0: %1",
  "Details": "Детайли",
  "Height": "Височина",
  "In best chain (%s confirmations)": [
    "Най-дългата верига (1 потвърждение)",
    "Най-дългата верига (%0 потвърждения)"
  ],
  "Included in Block": "Включена в блок",
  "lang_id": "bg",
  "lang_name": "Български",
  "Loading...": "Зарежда се…",
  "Load more": "Още резултати",
  "Next": "Следващ",
  "Nonstandard": "Нестандартна",
  "No results found": "Няма намерени резултати",
  "OP_RETURN data": "OP_RETURN данни",
  "Output in parent chain": "Дериват от веригата източник",
  "Page Not Found": "Страницата не е намерена",
  "Peg-out": "Peg-оut",
  "Peg-out address": "Peg-out адрес",
  "Peg-out to": "Peg-out до",
  "Previous": "Предишен",
  "Search for block height, hash, transaction, or address": "Въведете височина на блок, хаш, транзакция или адрес",
  "Size (KB)": "Размер (KB)",
  "%s of %s Transactions": "%0 от %1 Транзакции",
  "Spent by": "Похарчен от",
  "Status": "Статус",
  "%s Transactions": [
    "1 Транзакция",
    "%0 Транзакции"
  ],
  "Timestamp": "Времeви печат",
  "Transaction": "Транзакция",
  "Transaction fees": "Такси за транзакция",
  "Transactions": "Транзакции",
  "Transaction: %s": "Транзакция: %0",
  "Type": "Тип",
  "Unspent": "Не e похарчен",
  "Version": "Версия",
  "We encountered an error. Please try again later.": "Изникна грешка. Моля, опитайте по-късно.",
  "Weight (KWU)": "Тегло (KWU)",
  "Witness": "Подпис"
}
