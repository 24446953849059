module.exports={
  "Address": "Dirección",
  "Address: %s": "Dirección %0",
  "Block %s": "Bloque %0",
  "Block #%s: %s": "Bloque #%0: %1",
  "Details": "Detalles",
  "Height": "Altura",
  "In best chain (%s confirmations)": [
    "En cadena más larga (%0 confirmaciones)",
    "En cadena más larga (%0 confirmaciones)"
  ],
  "Included in Block": "Incluida en bloque",
  "lang_id": "es",
  "lang_name": "Español",
  "Loading...": "Cargando...",
  "Load more": "Mostrar siguiente",
  "Next": "Siguiente",
  "Nonstandard": "No estándar",
  "No results found": "No se encontraron resultados para su búsqueda",
  "OP_RETURN data": "Datos OP_RETURN",
  "Output in parent chain": "Salida en cadena principal",
  "Page Not Found": "Página No Encontrada",
  "Peg-out": "Peg-Out",
  "Peg-out address": "Dirección de Peg-out ",
  "Peg-out to": "Peg-out a",
  "Powered by esplora": "Desarrollado por esplora",
  "Previous": "Previo",
  "Search for block height, hash, transaction, or address": "Busca por altura de bloque, hash, transacción o dirección",
  "Size (KB)": "Tamaño (KB)",
  "%s of %s Transactions": "Transacciones %0 de %1",
  "Spent by": "Gastado por",
  "Status": "Estatus",
  "%s Transactions": [
    "Transacciones %0",
    "Transacciones %0"
  ],
  "Timestamp": "Fecha y Hora",
  "Transaction": "Transacción",
  "Transaction fees": "Tarifa for transacción",
  "Transactions": "Transacciones",
  "Transaction: %s": "Transacción: %0",
  "Type": "Tipo",
  "Unspent": "No gastado",
  "Version": "Versión",
  "We encountered an error. Please try again later.": "Encontramos un error. Por favor intenta nuevamente más tarde.",
  "Weight (KWU)": "Peso (KWU)",
  "Witness": "testigo"
}
