module.exports={
  "Address": "Adresse",
  "Address: %s": "Adresse: %0",
  "Block %s": "Bloc %0",
  "Block #%s: %s": "Bloc #%0: %1",
  "Confidential": "Confidentiel",
  "Details": "Détails",
  "Height": "Hauteur",
  "In best chain (%s confirmations)": [
    "Dans la chaine la plus longue (1 confirmation)",
    "Dans la chaine la plus longue (%0 confirmations)"
  ],
  "Included in Block": "Inclue dans le bloc",
  "lang_id": "fr",
  "lang_name": "Français",
  "Loading...": "Chargement...",
  "Load more": "Afficher plus",
  "Next": "Suivant",
  "No results found": "Aucun résultat",
  "Page Not Found": "Page non trouvée",
  "Previous": "Précédent",
  "%s Confirmations": [
    "1 confirmation",
    "%0 confirmations"
  ],
  "Search for block height, hash, transaction, or address": "Numéro de bloc, hash, transaction ou adresse",
  "Size (KB)": "Taille (Ko)",
  "%s of %s Transactions": "%0 sur %1 Transactions",
  "Spent by": "Dépensé par",
  "Status": "Statut",
  "%s Transactions": [
    "%0 Transactions",
    "%0 Transactions"
  ],
  "Timestamp": "Date",
  "Transaction fees": "Frais de transaction",
  "Transaction: %s": "Transaction: %0",
  "Unconfirmed": "Non confirmée",
  "Unspent": "Non dépensé",
  "Weight (KWU)": "Poids (KWU)"
}
