module.exports={
  "Address": "Indirizzo",
  "Address: %s": "Indirizzo: %0",
  "Amount commitment": "Commitment dell'Importo",
  "Asset commitment": "Commitment dell'Asset",
  "Block Challenge": "Challenge del Blocco",
  "Block height": "Altezza del Blocco",
  "Block not found": "Blocco Non Trovato",
  "Block %s": "Blocco %0",
  "Block Solution": "Soluzione del Blocco",
  "Block #%s: %s": "Blocco #%0: %1",
  "Block timestamp": "Timestamp del Blocco",
  "Broadcast raw transaction (hex)": "Trasmetti una Raw Transaction (hex)",
  "Broadcast transaction": "Trasmetti transazione",
  "Broadcast tx": "Trasmetti TX",
  "Cancel": "Annulla",
  "Confidential": "Confidenziale",
  "Confirmed": "Confermata",
  "Confirmed received": "Output confermati ricevuti",
  "Confirmed spent": "Output confermati spesi",
  "Confirmed tx count": "Numero di transazioni confermate",
  "Confirmed unspent": "Output confermati non spesi",
  "Details": "Dettagli",
  "Go": "Vai",
  "Height": "Altezza",
  "In best chain (%s confirmations)": [
    "Nella migliore catena (1 conferma)",
    "Nella migliore catena (%0 conferme)"
  ],
  "Included in Block": "Inclusa in Blocco",
  "Issuance": "Emissione",
  "Issuance blinding nonce": "Blinding nonce di emissione",
  "Issuance entropy": "Entropia di emissione",
  "lang_id": "it",
  "lang_name": "Italiano",
  "Loading...": "Caricamento in corso...",
  "Load more": "Continua",
  "New asset": "Nuovo Asset",
  "Newer": "Più recente",
  "Next": "Prossimo",
  "No outputs": "Nessun Output",
  "No reissuance": "Nessuna Riemissione",
  "No results found": "Nessun risultato trovato",
  "Older": "Più vecchio",
  "OP_RETURN data": "Dati di OP_RETURN",
  "Orphaned": "Orfano",
  "Output in parent chain": "Output nella catena padre",
  "Page Not Found": "Pagina Non Trovata",
  "Peg-out address": "Indirizzo di peg-out",
  "Peg-out to": "Peg-out verso",
  "Prev": "Precedente",
  "Previous": "Precedente",
  "Previous output address": "Indirizzo dell'output precedente",
  "Previous output script": "Script dell'output precedente",
  "Reissuance": "Riemissione",
  "Reissuance commitment": "Commitment di riemissione",
  "Reissuance keys": "Chiavi di riemissione",
  "Replace by fee": "Replace by fee ",
  "Scan QR": "Scansiona QR",
  "%s Confirmations": [
    "1 Conferma",
    "%0 Conferme"
  ],
  "Search for block height, hash, transaction, or address": "Cerca per altezza blocco, hash, transazione o indirizzo",
  "Size (KB)": "Dimensione (KB)",
  "%s of %s Transactions": "%0 di %1 Transazioni",
  "%s outputs": [
    "1 output",
    "%0 outputs"
  ],
  "Spending tx": "Speso nella transazione",
  "Spent by": "Spesa da",
  "Status": "Stato",
  "%s Transactions": [
    "%0 Transazioni",
    "%0 Transazioni"
  ],
  "Timestamp": "Data",
  "Total tx count": "Numero di transazioni totali",
  "Total unspent": "Totale output non spesi",
  "Transaction": "Transazione",
  "Transaction fees": "Commissione transazione",
  "Transaction not found": "Transazione non trovata",
  "Transactions": "Transazioni",
  "Transaction: %s": "Transazione: %0",
  "Type": "Tipologia",
  "Unconfirmed": "Non confermata",
  "Unconfirmed received": "Output non confermeti ricevuti",
  "Unconfirmed spent": "Output non confermati spesi",
  "Unconfirmed tx count": "Numero transazioni non confermate",
  "Unspent": "Non spesa",
  "Value commitment": "Commitment dell'importo",
  "Version": "Versione",
  "We encountered an error. Please try again later.": "C'è stato un problema. Per piacere riprova più tardi.",
  "Weight (KWU)": "Peso (KWU)"
}
