module.exports={
  "Address": "주소",
  "Address: %s": "주소: %0",
  "Block %s": "블록 %0",
  "Block #%s: %s": "블록 #%0: %1",
  "Coinbase": "코인베이스",
  "Details": "자세히",
  "Height": "블록 높이",
  "In best chain (%s confirmations)": [
    "현 체인 (%0 컨펌)",
    "현 체인 (%0 컨펌)"
  ],
  "Included in Block": "포함되어 있는 블록",
  "lang_id": "ko",
  "lang_name": "한국어",
  "Loading...": "로딩...",
  "Load more": "더 보기",
  "Lock time": "잠금 시간",
  "Next": "다음",
  "Nonstandard": "비표준",
  "No results found": "검색결과가 없습니다",
  "OP_RETURN data": "OP_RETURN 데이터",
  "Output in parent chain": "부모 체인 아웃풋",
  "Page Not Found": "페이지를 찾을 수 없음",
  "Peg-out": "페그 아웃",
  "Peg-out address": "페그 아웃 주소",
  "Peg-out ASM": "페그 아웃 ASM",
  "Peg-out to": "페그 아웃할 곳",
  "Previous": "이전",
  "Search for block height, hash, transaction, or address": "블록 높이, 해시, 주소, 또는 거래로 검색해 보세요",
  "Size (KB)": "사이즈 (KB)",
  "%s of %s Transactions": "%0 의 %1 거래들",
  "Spent by": "사용한 유저",
  "%s (%s sat/vB)": "%0 (%1 사토시/vB)",
  "Status": "상태",
  "%s Transactions": [
    "%0 거래들",
    "%0 거래들"
  ],
  "Timestamp": "타임 스탬프",
  "Transaction": "거래",
  "Transaction fees": "수수료",
  "Transactions": "거래",
  "Transaction: %s": "거래: %0",
  "Type": "유형",
  "Unspent": "사용안됨",
  "Version": "버전",
  "We encountered an error. Please try again later.": "오류가 발생했습니다. 나중에 다시 시도하십시시오.",
  "Weight (KWU)": "무게 (KWU)",
  "Witness": "서명"
}
