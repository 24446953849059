module.exports={
  "Address": "Адрес",
  "Block %s": "Процент (%0) Блока",
  "Details": "Подробные детали",
  "Height": "Высота",
  "In best chain (%s confirmations)": [
    "В лучшей цепи (процент потверждений)",
    "В лучшей цепи (процент потверждений)"
  ],
  "Included in Block": "Включенный в Блоке",
  "lang_id": "ru",
  "lang_name": "Русский",
  "Loading...": "Идёт Загрузка...",
  "Load more": "Смотреть больше",
  "Lock time": "Время блокировки",
  "Next": "Следующий",
  "Nonstandard": "Нестандартный",
  "No results found": "Результаты Не Найдены",
  "OP_RETURN data": "Данные OP_RETURN",
  "Output in parent chain": "Выходные данные в родительской цепи",
  "Page Not Found": "Страница Не Найдена",
  "Peg-out": "Закрепить",
  "Peg-out address": "Закрепительный адрес",
  "Peg-out to": "Закрепить к",
  "Previous": "Предыдущий",
  "Search for block height, hash, transaction, or address": "Поиск высоты блока, хэшов, транзакций или адресов",
  "Size (KB)": "Объем (Кбайт)",
  "%s of %s Transactions": "%0 от %1 Транзакций",
  "Spent by": "Потраченный",
  "Status": "Статус",
  "%s Transactions": [
    "Процент (%0) Транзакций",
    "Процент (%0) Транзакций"
  ],
  "Timestamp": "Штамп Времени",
  "Transaction": "Транзакция",
  "Transaction fees": "Комиссия за транзакцию",
  "Transactions": "Транзакции",
  "Type": "Тип",
  "Unspent": "Неизрасходованные",
  "Version": "Версия",
  "We encountered an error. Please try again later.": "Мы столкнулись с ошибкой. Пожалуйста, попробуйте позже.",
  "Weight (KWU)": "Вес (kWU)",
  "Witness": "Свидетельство"
}
