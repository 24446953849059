module.exports={
  "In best chain (%s confirmations)": [
    "In best chain (1 confirmation)",
    "In best chain (%0 confirmations)"
  ],
  "lang_id": "en",
  "lang_name": "English",
  "%s Confirmations": [
    "1 Confirmation",
    "%0 Confirmations"
  ],
  "%s outputs": [
    "1 output",
    "%0 outputs"
  ],
  "%s Transactions": [
    "1 Transaction",
    "%0 Transactions"
  ]
}
