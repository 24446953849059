module.exports={
  "Address": "地址",
  "Address: %s": "地址: %0",
  "Block height": "区块高度",
  "Block %s": "块 %0",
  "Block #%s: %s": "区块 #%0: %1",
  "Confidential": "保密",
  "Details": "细节",
  "Height": "块高度",
  "In best chain (%s confirmations)": [
    "在最正式的链 (确认数 1)",
    "在最正式的链 (确认数 %0)"
  ],
  "Included in Block": "在区块",
  "lang_id": "zh-cn",
  "lang_name": "中文(简体)",
  "Loading...": "页面加载...",
  "Load more": "更多",
  "Next": "后一个块",
  "No results found": "未找到结果",
  "Page Not Found": "网页未找到",
  "Previous": "前一个块",
  "%s Confirmations": [
    "确认数 1",
    "确认数 %0"
  ],
  "Search for block height, hash, transaction, or address": "高度、哈希、交易或地址...",
  "Size (KB)": "大小 (KB)",
  "%s of %s Transactions": "交易 %0 / %1",
  "Status": "状态",
  "%s Transactions": [
    "交易数量 %0",
    "交易数量 %0"
  ],
  "Timestamp": "时间戳",
  "Transaction": "交易",
  "Transaction fees": "矿工手续费",
  "Transactions": "交易",
  "Transaction: %s": "交易: %0",
  "Type": "类型",
  "Unconfirmed": "没确认",
  "Version": "版本",
  "Weight (KWU)": "重量 (KWU)"
}
