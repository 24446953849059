module.exports={
  "Address": "כתובת",
  "Address reuse": "שימוש חוזר בכתובות",
  "Address: %s": "כתובת: %0",
  "Asset ID": "מזהה נכס",
  "Block height": "גובה בלוק",
  "Block not found": "בלוק לא נמצא",
  "Block %s": "בלוק %0",
  "Block #%s: %s": "בלוק #%0: %1",
  "Block timestamp": "זמן בלוק",
  "Broadcast raw transaction (hex)": "פרסום טרנזקציה גולמית (hex)",
  "Broadcast transaction": "פרסם טרנזקציה",
  "Broadcast tx": "פרסם טרנזקציה",
  "Cancel": "בטל",
  "Coinbase": "פרס כרייה",
  "CoinJoin transactions hide the link between inputs and outputs and improves Bitcoin's overall privacy and fungibility for everyone.": "טרנזקציות CoinJoin מסתירות את הקשר בין קלטים לפלטים ומשפרים את הפרטיות והפאנג'יבילות של ביטקוין עבור כולם.",
  "compared to bitcoind's suggested fee of %s sat/vB for confirmation within 2 blocks": "בהשוואה לעמלה המומלצת של bitcoind של %s סטושי/בייט עבור אישור תוך 2 בלוקים",
  "Confidential": "חסוי",
  "Confirmed": "מאושר",
  "Confirmed received": "התקבל (מאושר)",
  "Confirmed spent": "נשלח (מאושר)",
  "Confirmed tx count": "מספר טרנזקציות מאושרות",
  "Confirmed unspent": "לא מנוצל (מאושר)",
  "Currently unavailable": "לא זמין כעת",
  "Details": "פרטים",
  "Esplora is currently unavailable, please try again later.": "אספלורה לא זמין כעת, אנא נסה שוב מאוחר יותר.",
  "ETA": "זמן אישור משוער",
  "Fee": "עמלה",
  "Height": "גובה",
  "In best chain": "בשרשרת הכי טובה",
  "In best chain (%s confirmations)": [
    "בשרשרת הכי טובה (אישור אחד)",
    "בשרשרת הכי טובה (%0 אישורים)"
  ],
  "Included in Block": "כלול בבלוק",
  "in %s blocks (%s from tip)": "בתוך %0 בלוקים (עומק mempool של %1)",
  "Issuance": "הנפקה",
  "Issuance amount": "כמות הנפקה",
  "lang_id": "he",
  "lang_name": "עברית",
  "Likely self-transfer": "העברה עצמית בסבירות גבוהה",
  "Loading...": "טוען...",
  "Load more": "טען עוד",
  "Lock time": "נעילת זמן",
  "Merkle root": "שורש מרקל",
  "New asset": "נכס חדש",
  "Newer": "חדש יותר",
  "Next": "הבא",
  "Nonstandard": "לא סטנדרטי",
  "No outputs": "ללא פלטים",
  "No recent transactions": "אין טרנזקציות אחרונות",
  "No reissuance": "ללא הנפקה מחדש",
  "No results found": "לא נמצאו תוצאות",
  "Older": "ישן יותר",
  "OP_RETURN data": "מידע OP_RETURN",
  "Opted in": "מופעל",
  "Orphaned": "יתום",
  "Outpoint": "מזהה פלט",
  "Output in parent chain": "מטבע משרשרת אם",
  "overpaying by %s%": "עמלת יתר של %s%",
  "Page Not Found": "עמוד לא נמצא",
  "Possibly a CoinJoin transaction": "נראה כמו טרנזקציית CoinJoin",
  "Prev": "הקודם",
  "Previous": "קודם",
  "Previous output address": "כתובת פלט קודם",
  "Previous output script": "סקריפט פלט קודם",
  "Privacy analysis": "ניתוח פרטיות",
  "Recent transactions": "טרנזקציות אחרונות",
  "Reissuance": "הנפקה נוספת",
  "Reissuance keys": "מפתחות הנפקה מחדש",
  "Round payment amount": "סכום תשלום עגול",
  "%s blocks": "%s בלוקים",
  "Scan QR": "סרוק QR",
  "%s Confirmations": [
    "אישור אחד",
    "%0 אישורים"
  ],
  "Search for block height, hash, transaction, or address": "חפש לפי גובה בלוק, האש בלוק, טרנזקציה או כתובת",
  "SegWit fee savings": "חסכון עמלות של סגוויט",
  "Sending exact amounts (with no change) is an indication the bitcoins likely didn't change hands.": "לשלוח סכום מדויק (ללא עודף) זו אינדקציה שהביטקוינים לא עברו בעלות.",
  "Sending to a different script type": "שליחה לסוג סקריפט שונה",
  "Size": "גודל",
  "Size (KB)": "גודל (KB)",
  "%s of %s Transactions": "%0 מתוך %1 טרנזקציות",
  "%s outputs": [
    "פלט 1",
    "%0 פלטים"
  ],
  "Spending tx": "נוצל ע\"י",
  "Spent by": "נוצל ע\"י",
  "Status": "סטטוס",
  "%s Transactions": [
    "טרנזקציה אחת",
    "%0 טרנזקציות"
  ],
  "style.css": "style-rtl.css",
  "There's an indication that one output is more likely to be the change because some inputs would've been unnecessary if it was the payment.": "קיימת אינדקציה שאחד הפלטים הוא ככל הנראה העודף, מכיוון שחלק מהקלטים לא היו נדרשים אם הוא היה התשלום.",
  "This transaction could save %s on fees by upgrading to native SegWit-Bech32 or %s by upgrading to SegWit-P2SH": "טרנזקציה זו יכולה לחסוך %s% בעמלות אם תשדרג לסגוויט-Bech32, או %s% אם תשדרג לסגוויט-P2SH",
  "This transaction doesn't violate any of the privacy gotchas we cover. Read on other potential ways it might leak privacy.": "טרנזקציה זו לא מפרה את בעיות הפרטיות שאנחנו מזהים. קרא על דרכים אחרות שהטרנזקציה עלולה לחשוף מידע פרטי.",
  "This transaction has unnecessary inputs that are not typically added by wallet software.": "טרנזקציה זו מכילה קלטים לא הכרחיים שלרוב לא מוכנסים ע\"י תוכנות ארנק סטנדרטיות.",
  "This transaction re-uses addresses. This makes it trivial to track your transactions over time and link them together. Note that we currently only look for address reuse within the transaction, not across the history.": "טרנזקציה זו עושה שימוש חוזר בכתובות. שימוש חזור בכתובות הופך מעקב אחרי הטרנזקציות שלך לטריוויאלי. שים לב שאנחנו כרגע בודקים שימוש חזור בכתובות בתוך הטרנזקציה בלבד, לא לאורך ההיסטוריה.",
  "This transaction saved %s on fees by upgrading to native SegWit-Bech32": "טרנזקציה זו חסכה %s% בעמלות ע\"י שדרוג לסגוויט-Bech32",
  "This transaction saved %s on fees by upgrading to SegWit and could save %s more by fully upgrading to native SegWit-Bech32": "טרנזקציה זו חסכה %s% בעמלות ע\"י שדרוג לסגוויט ויכולה לחסוך %s% יותר ע\"י שדרוג מלא לסגוויט-Bech33",
  "Timestamp": "זמן",
  "Total tx count": "סה\"כ טרנזקציות",
  "Total unspent": "סה\"כ לא מנוצל",
  "Transaction": "טרנזקציה",
  "Transaction fees": "עמלת טרנזקציה",
  "Transaction not found": "טרנזקציה לא נמצאה",
  "Transactions": "טרנזקציות",
  "Transaction: %s": "טרנזקציה: %0",
  "Type": "סוג",
  "Unconfirmed": "לא מאושר",
  "Unconfirmed received": "התקבל (לא מאושר)",
  "Unconfirmed spent": "נשלח (לא מאושר)",
  "Unconfirmed tx count": "מספר טרנזקציות לא מאושרות",
  "Unnecessary input heuristic": "קלט לא הכרחי",
  "Unspent": "לא נוצל",
  "Using round payment amounts gives an indication of which output is the payment and which is the change. Padding payment amounts to use all available precision resolves this issue.": "שימוש בסכומי תשלום עגולים נותנת אינדקציה להבחנה בין הפלט עודף לפלט תשלום. שימוש במלוא הדיוק האפשרי בסכום התשלום פותר את הבעיה.",
  "Value": "שווי",
  "Version": "גירסה",
  "Virtual size": "גודל וירטואלי",
  "We encountered an error. Please try again later.": "התרחשה שגיאה. נא לנסות שוב מאוחר יותר.",
  "Weight (KWU)": "משקל (KWU)",
  "Weight units": "יחידות משקל"
}
